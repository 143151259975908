import React from 'react';
import {useState, useEffect} from "react";

import "swiper/css";
import "swiper/css/navigation";
import '../../index.scss';
import './Gallery.scss';
import { queryURL, apiKey } from '../../constants';

import InstagramFeed  from 'react-ig-feed'
import 'react-ig-feed/dist/index.css'

const query =
`{
  instagramTokenCollection {
    items {
      token
    }
  }
}`

const Gallery = () => {
  const [page, setPage] = useState(null);

  useEffect(() => {
    window.fetch(queryURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: apiKey,
      },
      body: JSON.stringify({ query }),
    })
    .then((response) => response.json())
    .then(({ data, errors }) => {
      !errors ? setPage(data.instagramTokenCollection.items[0]) : console.error(errors);
    });
  }, []);

  if (!page) {
    return "";
  }

  try {
    return (

      <div id="Gallery" className="gallerySection section tb-padding">
        <h1>Gallery</h1>
        <div className="instaFeed">
          <InstagramFeed token={page.token} counter="18"/>  
        </div>
    </div>
    )
  } catch (error) {
    
  }
  
};

export default Gallery;
