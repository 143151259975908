import React, {useState, useEffect} from 'react';

import { queryURL, apiKey } from '../../constants';
import './Info.scss';
import '../../index.scss';

const query =
`{
  infoCollection {
    items {
      infoButtonText
      infoButtonBackgroundImage {
        url
      }
      infoButtonLink
      infoButtonDocument {
        url
      }
    }
  }
}`

const Info = () => {
  const [page, setPage] = useState(null);

  useEffect(() => {
    window.fetch(queryURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: apiKey,
      },
      body: JSON.stringify({ query }),
    })
    .then((response) => response.json())
    .then(({ data, errors }) => {
      !errors ? setPage(data.infoCollection.items) : console.error(errors);
    });
  }, []);

  if (!page) {
    return "";
  }

  return (
    <div id="Info" className="infoSection section">
      <h1 className="infoTitle">Club Info</h1>
      <div className="infoButtons">
      {page.map((item, index) => {
          return (
            <a key={"infoButton" + index} className="infoAnchor" href={item.infoButtonLink ? item.infoButtonLink : item.infoButtonDocument.url} target="_blank" rel="noreferrer">
            <div  className="infoButton" style={{  
              backgroundImage: `url(${item.infoButtonBackgroundImage.url})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}>
                <div className="infoInnerBorder">
                  <h2>{item.infoButtonText}</h2>
                </div>
            </div>
            </a>
          )})}
      </div>
    </div>
  )
};

export default Info;
