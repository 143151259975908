import React from 'react';
import { FaMapMarkerAlt } from "react-icons/fa";

import '../../index.scss';
import './Footer.scss';
import Social from '../Social/Social';

const Footer = () => (
  <div className="footer" id="Footer">
    <div className="footerContent">
    <div className="copyrightFooter">
        <h2 className="copyrightText">© 2022 Triton Swim Club</h2>
        <h2 className="copyrightText"><FaMapMarkerAlt/> Christchurch, NZ</h2>
      </div>
      <div className="contactFooterSection">
        <p>Stay in touch</p>
        <a className="contactAnchor" href="mailto:admin@tritonswimclub.co.nz"><h2>admin@tritonswimclub.co.nz</h2></a>
      </div>

      <div className="socialFooterSection">
        <p className="socialFooterText">Stay up to date</p>
        <Social/>
      </div>

    </div>
  </div>
);

export default Footer;