import React from 'react';
import {useState, useEffect} from "react";

import { queryURL, apiKey } from '../../constants';
import '../../index.scss';
import './Coaches.scss';

const query =
`{
  aboutCollection {
    items {
      coachImage {
        url
      }
    }
  }
}`

const Coaches = () => {
  const [page, setPage] = useState(null);

  useEffect(() => {
    window.fetch(queryURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: apiKey,
      },
      body: JSON.stringify({ query }),
    })
    .then((response) => response.json())
    .then(({ data, errors }) => {
      !errors ? setPage(data.aboutCollection.items[0]) : console.error(errors);
    });
  }, []);

  if (!page) {
    return "";
  }

  return (
    <div id="About" className="aboutSection section tb-padding">
      <div className="coachesDivs">
        <div className="coachesText">
          <h1>Coaches</h1>
          <p>Head Coach - Reuben Lim</p>
          <p>Assistant Coach - Jason Borea</p>
          <p>Assistant Coach - Bree Middleton</p>
          <p>Junior Coach - Stacey Morgan</p>
        </div>
        <div className="coachesImage">
            <img src={page.coachImage.url} alt={page.aboutTitle}/>
        </div>
      </div>
    </div>
  );
};

export default Coaches;